import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone, PrismicRichText, PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import { Layout } from '../components/Layout'
import { components } from '../slices'
import { Helmet } from 'react-helmet'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import * as sty from './Action.module.scss'

const ActionTemplate = ({ data }) => {
  if (!data) return null

  const actionContent = data.prismicAction || {}
  const allActions = data.allPrismicAction || {}
  const actions = allActions?.nodes || {}
  const action = actionContent.data || {}
  const menu = data.prismicMenu || {}

  const { lang, type, url, id } = actionContent || {}
  const alternateLanguages = actionContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  const colour = action.action_colour
  if (typeof document !== `undefined`) {
    document?.documentElement.style.setProperty('--action-colour', colour);
  }
  return (
    <>
      <Helmet
        htmlAttributes={{
          class: 'actionPages',
        }}
      >
      </Helmet>
      <Layout menu={menu.data} activeDocMeta={activeDoc}>

        <section className={sty.hero}>
          <div className={sty.imageBg}>
            <GatsbyImage
              image={action.background_image?.gatsbyImageData}
              alt={action.background_image?.alt}
              className={sty.image}
            />
          </div>
          <Container>
            <div className={sty.FlexWrap}>
              <div className={sty.copy}>
                <PrismicRichText field={action.page_title?.richText}/>
                <PrismicRichText field={action.page_description?.richText}/>
              </div>
              <div className={sty.imageBox}>
                <GatsbyImage
                  image={action.action_graphic?.gatsbyImageData}
                  alt={action.action_graphic?.alt}
                  className={sty.image}
                />
              </div>
            </div>
          </Container>
        </section>
        <SliceZone slices={action.body} components={components} context={{colour: action.action_colour,lang: lang, actions: actions, id: id}}/>
      </Layout>
    </>
  )
}

export const query = graphql`
  query actionQuery($id: String, $lang: String) {
    prismicAction(id: { eq: $id },lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      id
      data {
        page_title {
          richText
          text
        }
        page_description {
          richText
          text
        }
        action_colour
        action_graphic {
          alt
          url
          gatsbyImageData
        }
        background_image {
          alt
          gatsbyImageData
          url
        }
        body {
        ... on PrismicSliceType {
            id
            slice_type
            slice_label
            }
        
            ...ActionDataBodyActionsList
            ...ActionDataBodyCentreAlignedColumnsWPaper
            ...ActionDataBodyNewsMedia
            ...ActionDataBodyQuoteCarousel
            ...ActionDataBodySocials
            ...ActionDataBodyTextImage
        }
      }
    }
    allPrismicAction(filter: {lang: { eq: $lang}}) {
      nodes {
        url
        id
        data {
          page_title {
            richText
            text
          }
          action_colour
          action_graphic {
            alt
            url
            gatsbyImageData(
              layout: CONSTRAINED
            )
          }
        }
      }
      
    }
    prismicMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(ActionTemplate);
